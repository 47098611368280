<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Insert Link</v-card-title>

			<v-card-text>
				<!-- <v-text-field label="Text" v-model="rawText"></v-text-field> -->
				<v-text-field label="Link" v-model="rawLink"></v-text-field>
			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" class="ml-auto">Cancel</v-btn>
				<v-btn @click="modal.trigger('delete close')">Delete Link</v-btn>
				<v-btn :disabled="rawLink == ''" @click="save" color="primary">Save</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "getDate",

		props: {
			modal: {
				type: Object,
				required: true,
			},

			// text: {
			// 	type: String,
			// 	default: ''
			// },
			link: {
				type: String,
				default: ''
			},
		},

		data() {
			return {
				// rawText: this.text,
				rawLink: this.link
			};
		},

		methods: {
			save() {
				this.modal.trigger('save close', {
					// text: this.rawText,
					link: this.rawLink
				});
			}
		},

		watch: {
			// text(to) {
			// 	this.rawText = to;
			// },
			link(to) {
				this.rawLink = to;
			}
		}
	};
</script>